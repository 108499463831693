/* tslint:disable */
import { Injectable } from '@angular/core';
import { User } from '../../models/User';
import { Client } from '../../models/Client';
import { Thing } from '../../models/Thing';
import { TagGroup } from '../../models/TagGroup';
import { Tag } from '../../models/Tag';
import { Dataset } from '../../models/Dataset';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    User: User,
    Client: Client,
    Thing: Thing,
    TagGroup: TagGroup,
    Tag: Tag,
    Dataset: Dataset,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
