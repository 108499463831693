import { Component, OnInit } from '@angular/core';
import {Tag, TagGroup} from "../../shared/sdk/models";
import {TagApi, TagGroupApi} from "../../shared/sdk/services/custom";
import {_} from 'underscore';
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faCheck = faCheck;
  faTimes = faTimes;

  private groups = [TagGroup];
  private rootGroup: TagGroup;
  private tags = [Tag];

  private calculatorEnabled;

  constructor(private tagGroupApi: TagGroupApi, private tagApi: TagApi) { }

  ngOnInit() {
    this.getTagGroups();
    this.calculatorEnabled = false;
  }

  getTagGroups(): void {
    console.log('get tag groups');
    this.tagGroupApi.find().subscribe((tagGroups: []) => {
      this.groups = _.reject(tagGroups, (tagGroup: TagGroup) => {
        return false;
        //tagGroup.name === 'root';
      });
      this.rootGroup = _.findWhere(tagGroups, {name: 'root'});

      this.tagApi.find().subscribe((tags: []) => {
        console.log(this.rootGroup);
        this.tags = null;
        this.tags = _.map(_.reject(tags, (tag: Tag) => {
          return false;
          //tag.tagGroupId === this.rootGroup.id;
        }), (varTag) => {
          varTag.selected = false;
          return varTag;
        });
      });
    });
  }

  styleForTag(part1, tag) {
    if (!tag.selected) {
      part1 = part1 + 'outline-';
    }
    if (tag.score < 25) {
      part1 = part1 + 'danger';
    } else if (tag.score < 50) {
      part1 = part1 + 'warning';
    } else if (tag.score < 75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }


  styleForDatasetScore(part1: string, score){
    if (score < 2.25) {
      part1 = part1 + 'danger';
    } else if (score < 3.5) {
      part1 = part1 + 'warning';
    } else if (score <= 4.75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }

  tagsForGroup(groupId) {
    return _.sortBy(_.reject(this.tags, (tag) => {
      return !(tag.tagGroupId == groupId)
    }), (tag) => {
      return 0-tag.score;
    })
  }
  selectedTagsForGroup(groupId) {
    return _.reject(this.tags, (tag) => {
      return !(tag.tagGroupId == groupId && tag.selected)
    })
  }

  scoreForGroup(groupId) {
    let tags = this.selectedTagsForGroup(groupId);
    return (tags.length>0) ? _.pluck(tags, 'score').reduce((a, b) => a + b, 0) / tags.length : 0;
  }

  scoreForAllGroups() {
    return (_.map(this.groups, (group) => { return this.scoreForGroup(group.id)})).reduce((a,b) => a+b, 0) / (this.groups.length-1);
  }

}
