import { Component } from '@angular/core';
import { TagGroupApi, TagApi } from '../../../shared/sdk/services/custom';
import { TagGroup, Tag } from '../../../shared/sdk/models';
import { _ } from 'underscore';
import {faCheck, faPlusCircle, faTimes, faMinusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-manage-objects',
  templateUrl: './manage-variables.component.html',
  styleUrls: ['./manage-variables.component.scss']
})
export class ManageVariablesComponent {

  faCheck = faCheck;
  faTimes = faTimes;
  faPlus = faPlusCircle;
  faMinusCircle = faMinusCircle;

  private groups = [TagGroup];
  private rootGroup: TagGroup;
  private tags = [Tag];


  deleteMode = false;
  isAddingTagGroup = false;
  tagGroupBeingAdded: TagGroup;

  constructor( private tagGroupApi: TagGroupApi, private tagApi: TagApi) {
    this.getTagGroups();
  }


  newTagGroup(): void {
    this.tagGroupBeingAdded = new TagGroup();
    this.isAddingTagGroup = true;
  }

  saveTagGroup(): void {
    this.tagGroupApi.create(this.tagGroupBeingAdded).subscribe( (newGroup: TagGroup) => {
      if (!!newGroup) {
        // no message on success
      } else {
        alert('An error occurred!');
      }
      this.isAddingTagGroup = false;
      this.tagGroupBeingAdded = new TagGroup();
      this.getTagGroups();
    });
  }

  createTagInsideGroup(tagGroup): void {
    tagGroup.isAddingTag = true;
    tagGroup.sampleTag = new Tag();
    tagGroup.sampleTag.tagGroupId = tagGroup.id;
    tagGroup.sampleTag.score = 50;
  }

  saveTaginsideGroup(tag): void {
    // console.log(tag);
    this.tagApi.create(tag).subscribe((newTag) => {
      if (!!newTag) {
        // no message on success
      } else {
        alert('An error occurred!');
      }
      this.getTagGroups();
    });
  }

  getTagGroups(): void {
    console.log('get tag groups');
    this.tagGroupApi.find().subscribe((tagGroups: []) => {
      this.groups = _.reject(tagGroups, (tagGroup: TagGroup) => {
        return false;
        //tagGroup.name === 'root';
      });
      this.rootGroup = _.findWhere(tagGroups, {name: 'root'});

      this.tagApi.find().subscribe((tags: []) => {
        console.log(this.rootGroup);
        this.tags = null;
        this.tags = _.map(_.reject(tags, (tag: Tag) => {
          return false;
          //tag.tagGroupId === this.rootGroup.id;
        }), (varTag) => {
          varTag.selected = false;
          return varTag;
        });
      });
    });
  }

  tagsForGroup(groupId) {
    return _.sortBy(_.reject(this.tags, (tag) => {
      return !(tag.tagGroupId == groupId)
    }), (tag) => {
      return 0-tag.score;
    })
  }

  deleteTag(tagId): void {
    this.tagApi.deleteById(tagId).subscribe((data) => {
      console.log(data);
      this.getTagGroups();
    });
  }

  deleteTagGroup(groupId): void {
    this.tagGroupApi.deleteById(groupId).subscribe((data) => {
      console.log(data);
      this.getTagGroups();
    });
  }

  styleForScore(score: number) {
    if (score < 25) {
      return 'btn-danger';
    }
    if (score < 50) {
      return 'btn-warning';
    }
    if (score < 75) {
      return 'btn-info';
    }
    return 'btn-success';
  }
}
