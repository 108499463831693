/* tslint:disable */
import {
  TagGroup,
  Dataset
} from '../index';

declare var Object: any;
export interface TagInterface {
  "name": string;
  "description": string;
  "score": number;
  "id"?: any;
  "tagGroupId"?: any;
  tagGroup?: TagGroup;
  datasets?: Dataset[];
}

export class Tag implements TagInterface {
  "name": string;
  "description": string;
  "score": number;
  "id": any;
  "tagGroupId": any;
  tagGroup: TagGroup;
  datasets: Dataset[];
  constructor(data?: TagInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Tag`.
   */
  public static getModelName() {
    return "Tag";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Tag for dynamic purposes.
  **/
  public static factory(data: TagInterface): Tag{
    return new Tag(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Tag',
      plural: 'tags',
      path: 'tags',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "score": {
          name: 'score',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "tagGroupId": {
          name: 'tagGroupId',
          type: 'any'
        },
      },
      relations: {
        tagGroup: {
          name: 'tagGroup',
          type: 'TagGroup',
          model: 'TagGroup',
          relationType: 'belongsTo',
                  keyFrom: 'tagGroupId',
          keyTo: 'id'
        },
        datasets: {
          name: 'datasets',
          type: 'Dataset[]',
          model: 'Dataset',
          relationType: 'hasMany',
          modelThrough: 'Datasettag',
          keyThrough: 'datasetId',
          keyFrom: 'id',
          keyTo: 'tagId'
        },
      }
    }
  }
}
