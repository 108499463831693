import { Component } from '@angular/core';
import {Dataset, Tag, TagGroup} from '../../../shared/sdk/models';
import {DatasetApi, TagApi, TagGroupApi, UserApi} from '../../../shared/sdk/services/custom';
import { _ } from 'underscore';
import {faCheck, faPlusCircle, faTimes, faInfo, faMinusCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-manage-datasets',
  templateUrl: './manage-datasets.component.html',
  styleUrls: ['./manage-datasets.component.scss']
})
export class ManageDatasetsComponent {

  faPlus = faPlusCircle;
  faCheck = faCheck;
  faTimes =  faTimes;
  faInfo = faInfo;
  faMinusCircle = faMinusCircle;

  deleteMode = false;
  isAddingDataset = false;
  datasetBeingAdded: Dataset;
  showTags = false;
  scoring;

  private groups = [TagGroup];
  private rootGroup: TagGroup;
  private tags = [Tag];
  private types = [Tag];

  datasets = [Dataset];
  tagsToDataset = [Tag];
  nameToTag = new Map();

  constructor( private tagGroupApi: TagGroupApi,
               private tagApi: TagApi,
               private datasetApi: DatasetApi,
               private userApi: UserApi) {
    this.getTagGroups();
    this.getDatasets();
  }

  newDataset() {
    this.isAddingDataset = true;
    this.datasetBeingAdded = new Dataset();
  }

  // add Tags to a dataset, gets passed a list of tags and a name of a dataset to add the tags to
  addTags(tags: any, rootTags: any, dSetName: string): void {
    // console.log('get datasets');
    this.datasetApi.find().subscribe((datsets: [Dataset]) => {
      for (const entry in datsets) {
        if (datsets[entry].name === dSetName) {
          for (const tagEntry in tags) {
            // console.log('Entry: ', tagEntry,  tags[tagEntry].id);
            this.datasetApi.linkTags(datsets[entry].id, tags[tagEntry].id).subscribe((value: any) => {
              // console.log('Value: ', value);
            });
          }
          for (const rootTagEntry in rootTags) {
            console.log('rootEntry: ', rootTagEntry, rootTags[rootTagEntry].id);
            this.datasetApi.linkTags(datsets[entry].id, rootTags[rootTagEntry].id).subscribe((value: any) => {});
          }
        }
      }
      // @ts-ignore
      this.datasets = datsets;
    });
  }

  saveDataset(): void {
    //this.datasetBeingAdded.user = this.userApi.getCachedCurrent().username;
    const activeTags = _.where(_.union(this.tags), {selected: true});
    const rootTags = _.where(_.union(this.types), {selected: true});
    this.datasetApi.create(this.datasetBeingAdded).subscribe((newDS: Dataset) => {
      if (!!newDS) {
        alert('Successfully added');
      } else {
        alert('An error occurred');
      }
      this.isAddingDataset = false;
      this.datasetBeingAdded = new Dataset();
    });
    this.addTags(activeTags, rootTags, this.datasetBeingAdded.name);
  }

  deleteDataset(datasetId): void {
      this.datasetApi.deleteById(datasetId).subscribe((data) => {
        console.log(data);
        this.getDatasets();
      });
      this.deleteMode = false;
  }

  getTagGroups(): void {
    // console.log('get tag groups');
    this.tagGroupApi.find().subscribe((tagGroups: []) => {
      console.log('BBBBBB', tagGroups);
      this.groups = _.map(_.reject(tagGroups, (tagGroup: TagGroup) => {
        return tagGroup.name === 'Type';
      }), (tagGroup) => {
        tagGroup.isAddingTag = false;
        tagGroup.sampleTag = new Tag();
        return tagGroup;
      });
      this.rootGroup = _.findWhere(tagGroups, {name: 'Type'});

      this.tagApi.find().subscribe((tags: []) => {
        console.log('AAAA', this.rootGroup);
        this.tags = _.map(_.reject(tags, (tag: Tag) => {
          return tag.tagGroupId === this.rootGroup.id;
        }), (varTag) => {
          varTag.selected = false;
          return varTag;
        });
        this.types = _.map(_.reject(tags, (tag: Tag) => {
          return tag.tagGroupId !== this.rootGroup.id;
        }), (typeTag) => {
          typeTag.selected = true;
          return typeTag;
        });
      });
    });
  }

  getDatasets(): void {
    // console.log('get datasets');
    this.datasetApi.find().subscribe((datsets: [Dataset]) => {
      console.log('Datasets: ', datsets);
      console.log('DatasetID: ', datsets[datsets.length - 1].id);
      console.log('Tags to dataset: ', this.datasetApi.getTags(datsets));
      // @ts-ignore
      this.datasets = datsets;
      for (const element in datsets) {
        this.datasetApi.getTags(datsets[element].id).subscribe((dataSetTags: any) => {
          this.tagsToDataset = dataSetTags;
          this.nameToTag.set(datsets[element].name, this.tagsToDataset);
          // console.log('trying', datsets[element].name, this.tagsToDataset);
        });
      }
    });
  }


  styleForTag(tag) {
    let part1 = 'btn-';
    if (!tag.selected) {
      part1 = part1 + 'outline-';
    }
    if (tag.score < 25) {
      part1 = part1 + 'danger';
    } else if (tag.score < 50) {
      part1 = part1 + 'warning';
    } else if (tag.score < 75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }

  styleForScore(score: number) {
    if (score < 25) {
      return 'btn-danger';
    }
    if (score < 50) {
      return 'btn-warning';
    }
    if (score < 75) {
      return 'btn-info';
    }
    return 'btn-success';
  }

  styleForDatasetScore(tagList: any) {
    let part1 = 'btn-';
    let sum = 0;
    let overAllScore = 0;
    let len = 0;
    for (const tagValue in tagList) {
      sum += tagList[tagValue].score;
      len += 1;
    }
    overAllScore = ((sum / len) / 100) * 5; // + 1;
    this.scoring = Math.round(overAllScore * 100) / 100;
    if (overAllScore < 2.25) {
      part1 = part1 + 'danger';
    } else if (overAllScore < 3.5) {
      part1 = part1 + 'warning';
    } else if (overAllScore < 4.75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }
}
