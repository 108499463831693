import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SDKToken} from "../../../shared/sdk/models";
import {UserApi} from "../../../shared/sdk/services/custom";
import {LoopBackAuth} from "../../../shared/sdk/services/core";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  rememberMe: boolean;

  constructor(
    private userApi: UserApi,
    private authService: LoopBackAuth,
    private router: Router) {
  }

  ngOnInit() {
    console.log(this.authService.getToken());
  }

  formIsValid(): boolean {
    let valid = true;
    valid = valid && !!this.email && this.email.length > 0;
    valid = valid && !!this.password && this.password.length > 0;
    return valid;
  }

  login() {
  let credentials = { email: this.email, password: this.password , rememberMe: this.rememberMe};
    console.log("log in with: ", credentials);
    this.userApi.login(credentials).subscribe((token: SDKToken) => {
      console.log('login token: ', token);
      this.authService.setRememberMe(credentials.rememberMe);
      this.authService.setToken(token);
      this.authService.save();
      this.router.navigate(['/home']);

    }, err => {
      alert(err && err.message ? err.message : 'Login failed!');
      this.password = ''
    });

  }
}
