import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {TagGroupApi, TagApi} from '../../../shared/sdk/services/custom';
import {TagGroup, Tag} from '../../../shared/sdk/models';
import {_} from 'underscore';

import {faCheck, faTimes, faInfo, faSearch, faQuestion} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {

  faCheck = faCheck;
  faTimes = faTimes;
  faInfo = faInfo;
  faQuestion = faQuestion;
  faSearch = faSearch;

  queryText: String;

  queryType = {
    molecular: false,
    phenotypic: true,
    social: true
  };
  showAdanced = false;


  private groups = [TagGroup];
  private rootGroup: TagGroup;
  private tags = [Tag];
  private types = [Tag];

  constructor(private tagGroupApi: TagGroupApi, private tagApi: TagApi, private router: Router) {
    this.getTagGroups(); // Get room id by params or whatever mechanism
    localStorage.removeItem('rwdQuery');
  }

  getTagGroups(): void {
    console.log('get tag groups');
    this.tagGroupApi.find().subscribe((tagGroups: []) => {
      this.groups = _.reject(tagGroups, (tagGroup: TagGroup) => {
        return tagGroup.name === 'Type';
      });
      this.rootGroup = _.findWhere(tagGroups, {name: 'Type'});

      this.tagApi.find().subscribe((tags: []) => {
        console.log(this.rootGroup);
        this.tags = null;
        this.tags = _.map(_.reject(tags, (tag: Tag) => {
          return tag.tagGroupId === this.rootGroup.id;
        }), (varTag) => {
          varTag.selected = false;
          return varTag;
        });
        this.types = null;
        this.types = _.map(_.reject(tags, (tag: Tag) => {
          return tag.tagGroupId !== this.rootGroup.id;
        }), (typeTag) => {
          typeTag.selected = true;
          return typeTag;
        });
      });
    });
  }

  getSelectedTags(): [any] {
    return _.where(this.tags, {selected: true});
  }

  search(): void {
    localStorage.removeItem('rwdQuery');
    console.log(this.queryText);
    const activeTags = _.where(_.union(this.types, this.tags), {selected: true});
    console.log('searchFor', activeTags);

    localStorage.setItem('rwdQuery', JSON.stringify({
      textQuery: this.queryText,
      tags: activeTags
    }));
    this.router.navigateByUrl('/searchResults');
  }

  styleForTag(tag) {
    let part1 = 'btn-';
    if (!tag.selected) {
      part1 = part1 + 'outline-';
    }
    if (tag.score < 25) {
      part1 = part1 + 'danger';
    } else if (tag.score < 50) {
      part1 = part1 + 'warning';
    } else if (tag.score < 75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }


  tagsForGroup(groupId) {
    return _.sortBy(_.reject(this.tags, (tag) => {
      return !(tag.tagGroupId == groupId)
    }), (tag) => {
      return 0-tag.score;
    })
  }
}
