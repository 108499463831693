import {Component} from '@angular/core';
import {
  faSearch,
  faHome,
  faUser,
  faHandsHelping,
  faQuestionCircle,
  faMap,
  faHeart, faEnvelope, faPhone, faFax
} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {LoopBackAuth} from "./shared/sdk/services/core";
import {UserApi} from "./shared/sdk/services/custom";
import {environment} from "../environments/environment";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  faSearch = faSearch;
  faHome = faHome;
  faUser = faUser;
  faHelp = faQuestionCircle;
  faAbout = faHandsHelping;
  faMap = faMap;
  faHeart = faHeart;
  faEnvelope = faEnvelope;
  faPhone = faPhone;
  faFax = faFax;
  constructor(private auth: LoopBackAuth,
              private userApi: UserApi,
              private router: Router) {

  }

  version = environment.releaseVersion;


  onLogoutClick(): void{
    this.userApi.logout();
    alert('Logout successful');
    this.router.navigate(['/home']);
  }

  isAuthenticated(): boolean {
    return !!this.auth.getCurrentUserData();
  }

  getUserName(): any {
    return this.auth.getCurrentUserData().given_name + ' ' + this.auth.getCurrentUserData().family_name;
  }

  isAdmin(): boolean {
    if (!this.auth.getCurrentUserData()) {
      return false;
    } else {
      return this.auth.getCurrentUserData().role === 'admin';
    }
  }
}
