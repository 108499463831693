/* tslint:disable */
import {
  Client
} from '../index';

declare var Object: any;
export interface ThingInterface {
  "thing1"?: string;
  "id"?: any;
  "clientId"?: any;
  client?: Client;
}

export class Thing implements ThingInterface {
  "thing1": string;
  "id": any;
  "clientId": any;
  client: Client;
  constructor(data?: ThingInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Thing`.
   */
  public static getModelName() {
    return "Thing";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Thing for dynamic purposes.
  **/
  public static factory(data: ThingInterface): Thing{
    return new Thing(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Thing',
      plural: 'Things',
      path: 'Things',
      idName: 'id',
      properties: {
        "thing1": {
          name: 'thing1',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "clientId": {
          name: 'clientId',
          type: 'any'
        },
      },
      relations: {
        client: {
          name: 'client',
          type: 'Client',
          model: 'Client',
          relationType: 'belongsTo',
                  keyFrom: 'clientId',
          keyTo: 'id'
        },
      }
    }
  }
}
