import { Component, OnInit } from '@angular/core';
import { DatasetApi } from "../../../shared/sdk/services/custom";
import { _ } from 'underscore';
import {Dataset, Tag, TagGroup} from "../../../shared/sdk/models";
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
  screenHeight: number;
  screenWidth: number;

  chartsHeight: number;
  chartsWidth: number;

  statistics: {
    counts: {
      total: number,
      phenotypic: number,
      social: number,
      molecular: number,
    },
    scores: {
      total: number,
      phenotypic: number,
      social: number,
      molecular: number,
    },
    datasets: [Dataset],
  };

  available_kinds = ['Social','Molecular','Phenotypical'];
  available_tagGroups = [];
  available_tags = [];
  available_sources = [];
  table1;
  table2a;
  table2b;
  table3;

  colorSchemes = ['cool','vivid','picnic','acqua','nightLights'];
  currentLevel: number;

  showQualities: boolean;

  xAxisLabel = 'Dataset type';
  yAxisLabel = 'Count';

  formatting;

  constructor(private datasetApi: DatasetApi) {
    this.getScreenSize();
    this.formatting = this.formatValue.bind(this);
  }
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.chartsHeight = Math.min(600, this.screenHeight);
    this.chartsWidth = Math.min(300, this.screenWidth);
    console.log(this.screenHeight, this.screenWidth);
  }

  styleForHeaderOfPublished(tag: any) {
    if (!!tag.publication) {
      return this.styleForDatasetScore('bg-', tag);
    }
    return '';
  }
  isPrimaryTag(tag: any): boolean {
    return (tag.name == 'Molecular' || tag.name == 'Social' || tag.name == 'Phenotypical');
  }
  styleForDatasetScore(part1: string, tag: any){
    if (part1==='badge-' && !!tag.publication) {
      part1 = part1 + 'light';
      return part1;
    }
    if (tag.score < 2.25) {
      part1 = part1 + 'danger';
    } else if (tag.score < 3.5) {
      part1 = part1 + 'warning';
    } else if (tag.score <= 4.75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }
  styleForScore(tag: any): string {
    let part1 = 'btn-';
    if (this.isPrimaryTag(tag)) {
      part1 = part1+'outline-dark';
    } else
    if (tag.score < 25) {
      part1 = part1 + 'danger';
    } else
    if (tag.score < 50) {
      part1 = part1+ 'warning';
    } else
    if (tag.score < 75) {
      part1 = part1+ 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }
  ngOnInit() {
    this.currentLevel = 0;
    this.datasetApi.getStatistics().subscribe((data) => {
      this.statistics = data.statistics;
      this.table1 = [
        {
          "name": "Social",
          "value": this.statistics.counts.social
        },
        {
          "name": "Molecular",
          "value": this.statistics.counts.molecular
        },
        {
          "name": "Phenotypical",
          "value": this.statistics.counts.phenotypic
        }
      ];

      this.table2b = [
        {
          "name": "Social",
          "value": this.statistics.scores.social
        },
        {
          "name": "Molecular",
          "value": this.statistics.scores.molecular
        },
        {
          "name": "Phenotypical",
          "value": this.statistics.scores.phenotypic
        }
      ];

      this.table2a = [{
        "name" : "Overall score",
        "value": this.statistics.scores.total
      }
      ];

      this.gatherTree();

    });

  }

  selectedLevel1_meta;
  selectedLevel2_meta;
  selectedLevel3_meta;
  selectedLevel4_meta;
  selectedLevel4_datasets;

  resetLevels($event): void {
    //console.log($event);
    if (!$event) {
      this.selectedLevel1_meta = null;
      this.selectedLevel2_meta = null;
      this.selectedLevel3_meta = null;
      this.selectedLevel4_meta = null;
      this.selectedLevel4_datasets = [];
      this.currentLevel = 0;
      this.showQualities = false;
    } else {
      if ($event.name==='Overall score') {
        this.currentLevel = 0;
        this.showQualities = true;
      } else {
        if ($event.percent) {
          this.showQualities = true;
        } else {
          this.showQualities = false;
        }
        this.currentLevel = 1;
        this.selectedLevel1_meta = $event.name;
      }
    }

    this.gatherTree();
  }
  switchCategoryInLevel(level,category) {
    if (level==1) {
      this.selectedLevel1_meta = category;
      this.selectedLevel2_meta = null;
      this.selectedLevel3_meta = null;
      this.selectedLevel4_meta = null;
    } else if (level==2) {
      this.selectedLevel2_meta = category;
      this.selectedLevel3_meta = null;
      this.selectedLevel4_meta = null;
    } else if (level==3) {
      this.selectedLevel3_meta = category;
      this.selectedLevel4_meta = null;
    } else if (level==4) {
      this.selectedLevel4_meta = category;
    }
    if (level<=this.currentLevel) {
      this.currentLevel = level+1;
    }
    this.gatherTree();
  }
  switchLevel($event) {
    if (this.currentLevel==0) {
      this.currentLevel++;
      this.gatherTree();
    } else if (this.currentLevel==1) {
      this.selectedLevel1_meta = $event.name;
      this.currentLevel++;
      this.gatherTree();
    } else if (this.currentLevel==2) {
      this.selectedLevel2_meta = $event.name;
      this.currentLevel++;
      this.gatherTree();
    } else if (this.currentLevel==3) {
      this.selectedLevel3_meta = $event.name;
      this.currentLevel++;
      this.gatherTree();
    } else if (this.currentLevel==4) {
      this.selectedLevel4_meta = $event.name;
      this.currentLevel++;
      this.gatherTree();
    }
  }
  switchView(): void {
    this.showQualities = !this.showQualities;
    this.gatherTree();
  }
  gatherTree(): void {
    this.table3 = [];

    if (this.currentLevel==0) {
      if (this.showQualities) {
        this.table3 = this.table2a;
      } else {
        this.table3.push({
          "name": "Total",
          "value": this.statistics.counts.total
        })
      }
    } else if (this.currentLevel==1) {
      if (this.showQualities) {
        this.table3 = this.table2b;
      } else {
        this.table3 = this.table1;
      }
    } else if (this.currentLevel==2) {
      //console.log(this.selectedLevel1_meta);

      let selectedSubset = _.filter(this.statistics.datasets, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.name === this.selectedLevel1_meta;
        });
        return tags_match.length>0;
      });

      //console.log('Selected count:', selectedSubset.length);

      let allTagGroups = _.filter(_.uniq(_.flatten(_.map(selectedSubset, (dataset: Dataset) => {
        return _.map(dataset.tags, (tag: Tag) => {
          return tag.tagGroup.name
        })
      }))), (taggroupname) => {return taggroupname!='root'});

      //console.log(allTagGroups)
      this.available_tagGroups = allTagGroups;
      this.table3 = _.map(allTagGroups, (tagGroup) => {
        let metric = 0;
        if (this.showQualities) {

          let avgDsScoresPerGroup = _.map(selectedSubset, (dataset: Dataset) => {
            let matchingTags = _.filter(dataset.tags, (tag: Tag) => {
              return tag.tagGroup.name === tagGroup;
            });
            if (matchingTags.length>0) {
              return _.reduce(matchingTags, (m,n) => {return m+(!!n.score?n.score:0)},0) / matchingTags.length;
            } else {
              return 0;
            }
          });

          //console.log(avgDsScoresPerGroup);

          if (avgDsScoresPerGroup.length>0) {
            metric = _.reduce(avgDsScoresPerGroup, (m,n) => {return m+n}) / avgDsScoresPerGroup.length;
          } else {
            metric = 0;
          }

        } else {
          let datasetsOfTagGoup = _.filter(selectedSubset, (subset: Dataset) => {
            let matchTags = _.filter(subset.tags, (tag: Tag) => {
              return tag.tagGroup.name === tagGroup;
            });
            return (matchTags.length>0);
          });
          metric = datasetsOfTagGoup.length;
          //console.log(datasetsOfTagGoup, metric);
        }
        return {
          name: tagGroup,
          value: metric
        }
      });

    } else if (this.currentLevel==3) {

      let selectedSubset = _.filter(this.statistics.datasets, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.name === this.selectedLevel1_meta;
        });
        return tags_match.length>0;
      });

      let tagsOfLevel = _.uniq(_.flatten(_.map(selectedSubset, (dataset: Dataset) => {
        return _.map(_.filter(dataset.tags, (tag: Tag) => {
          return tag.tagGroup.name === this.selectedLevel2_meta;
        }), (tag: Tag) => {return tag.name});
      })));

      this.available_tags = tagsOfLevel;

      this.table3 = _.map(tagsOfLevel, (tagName) => {
        let metric = 0;
        let filteredSubSubset = _.filter(selectedSubset, (dataset: Dataset) => {
          let matchTags = _.filter(dataset.tags, (tag: Tag) => {return tag.name === tagName})
          return (matchTags.length>0);
        });

        if (filteredSubSubset.length>0) {
          if (this.showQualities) {
            metric = _.reduce(filteredSubSubset, (m,n) => {return m+(!!n.score?n.score:0)},0) / filteredSubSubset.length;
          } else {
            metric = filteredSubSubset.length;
          }

        } else {
          metric = 0;
        }
        return {
          name: tagName,
          value: metric
        }
      })


    } else if (this.currentLevel==4) {
      let selectedSubset = _.filter(this.statistics.datasets, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.name === this.selectedLevel1_meta;
        });
        return tags_match.length>0;
      });
      selectedSubset = _.filter(selectedSubset, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.tagGroup.name === this.selectedLevel2_meta;
        });
        return tags_match.length>0;
      });
      selectedSubset = _.filter(selectedSubset, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.name === this.selectedLevel3_meta;
        });
        return tags_match.length>0;
      });

      let sources = _.uniq(_.map(selectedSubset, (dataset: Dataset) => {return dataset.source}));

      this.available_sources = sources;
      this.table3 = _.map(sources, (source) => {
        let datasetsForSource = _.filter(selectedSubset, (dataset: Dataset) => {return dataset.source===source});
        let metric = 0;
        if (datasetsForSource.length>0) {
          if (this.showQualities) {
            metric = _.reduce(datasetsForSource, (m,n)=>{return m+(!!n.score?n.score:0)},0) / datasetsForSource.length;
          } else {
            metric = datasetsForSource.length;
          }
        }
        return {
          name: source,
          value: metric
        }
      })

    } else if (this.currentLevel==5) {
      let selectedSubset = _.filter(this.statistics.datasets, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.name === this.selectedLevel1_meta;
        });
        return tags_match.length>0;
      });
      selectedSubset = _.filter(selectedSubset, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.tagGroup.name === this.selectedLevel2_meta;
        });
        return tags_match.length>0;
      });
      selectedSubset = _.filter(selectedSubset, (dataset: Dataset) => {
        let tags_match = _.filter(dataset.tags, (tag: Tag) => {
          return tag.name === this.selectedLevel3_meta;
        });
        return tags_match.length>0 && dataset.source == this.selectedLevel4_meta;
      });

      this.selectedLevel4_datasets = selectedSubset;

      this.table3 = _.map([this.selectedLevel4_meta], (source) => {
        let subsubsets = _.filter(this.selectedLevel4_datasets, (dataset: Dataset) => {return dataset.source==source});
        let metric = 0;
        //console.log(this.selectedLevel4_datasets, this.selectedLevel4_meta, subsubsets);

        if (subsubsets.length>0) {
          if (this.showQualities) {
            metric = _.reduce(subsubsets, (m,n)=>{return m+(!!n.score?n.score:0)},0) / subsubsets.length;
          } else {
            metric = subsubsets.length;
          }
        }
        return {
          name: source,
          value: metric
        }
      })
    }


  }

  formatValue(e): string {
    if (this.currentLevel==2 && this.showQualities) return e+' %';
    return e;
  }
}
