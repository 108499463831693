/* tslint:disable */
import {
  Tag
} from '../index';

declare var Object: any;
export interface DatasetInterface {
  "name": string;
  "description"?: string;
  "source": string;
  "sourceUrl"?: string;
  "publication"?: string;
  "score"?: number;
  "approved"?: boolean;
  "id"?: any;
  tags?: Tag[];
}

export class Dataset implements DatasetInterface {
  "name": string;
  "description": string;
  "source": string;
  "sourceUrl": string;
  "publication": string;
  "score": number;
  "approved": boolean;
  "id": any;
  tags: Tag[];
  constructor(data?: DatasetInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Dataset`.
   */
  public static getModelName() {
    return "Dataset";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Dataset for dynamic purposes.
  **/
  public static factory(data: DatasetInterface): Dataset{
    return new Dataset(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Dataset',
      plural: 'datasets',
      path: 'datasets',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "description": {
          name: 'description',
          type: 'string'
        },
        "source": {
          name: 'source',
          type: 'string'
        },
        "sourceUrl": {
          name: 'sourceUrl',
          type: 'string'
        },
        "publication": {
          name: 'publication',
          type: 'string'
        },
        "score": {
          name: 'score',
          type: 'number'
        },
        "approved": {
          name: 'approved',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'any'
        },
      },
      relations: {
        tags: {
          name: 'tags',
          type: 'Tag[]',
          model: 'Tag',
          relationType: 'hasMany',
          modelThrough: 'Datasettag',
          keyThrough: 'tagId',
          keyFrom: 'id',
          keyTo: 'datasetId'
        },
      }
    }
  }
}
