import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import {SearchComponent} from './components/datasets/search/search.component';
import {HomeComponent} from './components/home/home.component';
import {SearchResultsComponent} from './components/datasets/search-results/search-results.component';
import {ManageVariablesComponent} from './components/admin/manage-variables/manage-variables.component';
import {ManageDatasetsComponent} from './components/admin/manage-datasets/manage-datasets.component';
import {LoginComponent} from './components/account/login/login.component';
import {RegisterComponent} from './components/account/register/register.component';
import {AuthGuard} from './components/account/authentication';
import {StatisticsComponent} from './components/datasets/statistics/statistics.component';
import {FaqComponent} from "./components/faq/faq.component";
import {AboutComponent} from "./components/about/about.component";

const routes: Routes = [
  {
    path: 'search',
    component: SearchComponent,
  },
  {
    path: 'searchResults',
    component: SearchResultsComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'help',
    component: FaqComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  },/*
  {
    path: 'manage-variables',
    component: ManageVariablesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-datasets',
    component: ManageDatasetsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },*/
  {
    path: 'statistics',
    component: StatisticsComponent,
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
