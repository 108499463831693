import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import {LoopBackAuth} from "../../../shared/sdk/services/core";
import {Observable} from "rxjs";
import {UserApi} from "../../../shared/sdk/services/custom";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  profile: any;
  error: any;


  constructor(private authService: LoopBackAuth, private userApi: UserApi, private router: Router) {
    this.profile = {
      family_name : null,
      given_name : null,
      institution : null,
      email : null,
      password : null,
      passwordConfirm : null
    };
  }

  ngOnInit() {
  }

  passwordsMatch(): boolean {
    let match = true;
    match = match && (!!this.profile.password && this.profile.password.length > 0);
    match = match && (!!this.profile.passwordConfirm && this.profile.passwordConfirm.length > 0);
    match = match && (this.profile.password == this.profile.passwordConfirm);
    return match;
  }

  formIsValid(): boolean {
    let valid = true;
    valid = valid && (!!this.profile.given_name && this.profile.given_name.length > 0);
    valid = valid && (!!this.profile.family_name && this.profile.family_name.length > 0);
    valid = valid && (!!this.profile.institution && this.profile.institution.length > 0);
    valid = valid && (!!this.profile.email && this.profile.email.length > 0);
    valid = valid && this.passwordsMatch();
    return valid;
  }

  signUp() {
    //console.log(this.profile);
    if (!this.formIsValid()) return;
    this.registerForm(this.profile).subscribe((res: any) => {
      //console.log('signup reply: ', res);
      this.error = null;
      alert("Registration successful");
      this.router.navigate(['/login']);

    }, (error: any) => {
      console.log('signup error: ', error);
      this.error = error;
      alert(this.error.message)
    });
  }

  registerForm(profile: any) {
    return this.userApi.create({
      family_name: profile.family_name,
      given_name: profile.given_name,
      institution: profile.institution,
      email: profile.email,
      password: profile.password,
      realm: 'local',  // TODO: switch to a DB hook
      role: 'user', // TODO: switch to a DB hook
    });
  }

}
