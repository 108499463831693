import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { HttpClientModule} from '@angular/common/http';
import {LoopBackAuth, SDKBrowserModule} from './shared/sdk';
import { HomeComponent } from './components/home/home.component';
import { SearchComponent } from './components/datasets/search/search.component';
import {RouterModule, Routes} from '@angular/router';
import { SearchResultsComponent } from './components/datasets/search-results/search-results.component';
import { ManageVariablesComponent } from './components/admin/manage-variables/manage-variables.component';
import { ManageDatasetsComponent } from './components/admin/manage-datasets/manage-datasets.component';
import { LoginComponent } from './components/account/login/login.component';
import { RegisterComponent } from './components/account/register/register.component';
import {AuthGuard} from './components/account/authentication';
import { StatisticsComponent } from './components/datasets/statistics/statistics.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { FaqComponent } from './components/faq/faq.component';
import { AboutComponent } from './components/about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchComponent,
    SearchResultsComponent,
    ManageVariablesComponent,
    ManageDatasetsComponent,
    LoginComponent,
    RegisterComponent,
    StatisticsComponent,
    FaqComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    SDKBrowserModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    NgxChartsModule
  ],
  providers: [AuthGuard, LoopBackAuth],
  bootstrap: [AppComponent]
})
export class AppModule { }
