import { Component, OnInit } from '@angular/core';
import { DatasetApi } from '../../../shared/sdk/services/custom';
import { Dataset } from '../../../shared/sdk/models';
import { Tag } from '../../../shared/sdk/models';
import { _ } from 'underscore';
import {LoopBackAuth} from "../../../shared/sdk/services/core";
import { faEdit, faLock, faUnlock, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {Data} from "@angular/router";

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {

  rootTagGroup = '5dd267b10fafaa664e800b33';
  rootTags = [];
  otherTags = [];
  showAdanced: boolean;
  faEdit = faEdit;
  faLock = faLock;
  faUnlock = faUnlock;
  faSave = faSave;
  faTimes = faTimes;

  datasets: [Dataset];
  selectedDataset: Dataset;
  searchParams: {
    textQuery: String,
    tags: [Tag]
  };

  constructor(private datsetApi: DatasetApi, private auth: LoopBackAuth, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.searchParams = JSON.parse(localStorage.getItem('rwdQuery'));
    this.showAdanced = false;
    this.getSearchResults();
  }


  isAdmin(): boolean {
    if (!this.auth.getCurrentUserData()) {
      return false;
    } else {
      return this.auth.getCurrentUserData().role === 'admin';
    }
  }

  openAdminEditor(content, dataset: Dataset): void {
    this.selectedDataset = dataset;
    this.modalService.open(content, {
      backdropClass: 'light-blue-backdrop',
      centered: true,
      size: 'xl'
    });
  }

  getSearchResults(): void {

    this.rootTags = [];
    this.otherTags = [];

    this.rootTags = _.filter(this.searchParams.tags, (tag: Tag) => {return tag.tagGroupId === this.rootTagGroup});
    this.otherTags = _.filter(this.searchParams.tags, (tag: Tag) => {return tag.tagGroupId !== this.rootTagGroup});

    const queryTagsParams = _.map(this.searchParams.tags, (tag: Tag) => {
      return {
        id: tag.id
      };
    });

    const pattern = '/.*' + this.searchParams.textQuery + '.*/i';

    const query = {
      include: {
        relation: 'tags', // include the tags object
        scope: { // further filter the tags object
          where: {
            and: queryTagsParams
          }
        }
      },
      where: {
        or: [
          {name: {regexp: pattern}},
          {description: {regexp: pattern}},
          {source: {regexp: pattern}},
          {sourceUrl: {regexp: pattern}}
        ]
      }
    };

    console.log(query);

    this.datsetApi.find(query).subscribe((datsets: [Dataset]) => {
      for (let element in datsets) {
        this.datsetApi.getScore(datsets[element].id).subscribe((score) => {
          datsets[element].score = score.score;
          this.reorderResults();
          this.datsetApi.getTags(datsets[element].id).subscribe((tags) => {
            datsets[element].tags = tags;
          })
        })
      }
      this.datasets = datsets;
      this.reorderResults();
    });
  }

  styleForHeaderOfPublished(tag: any) {
    if (!!tag.publication) {
      return this.styleForDatasetScore('bg-', tag);
    }
    return '';
  }

  styleForDatasetScore(part1: string, tag: any){
    if (part1==='badge-' && !!tag.publication) {
      part1 = part1 + 'light';
      return part1;
    }
    if (tag.score < 2.25) {
      part1 = part1 + 'danger';
    } else if (tag.score < 3.5) {
      part1 = part1 + 'warning';
    } else if (tag.score <= 4.75) {
      part1 = part1 + 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }

  styleForScore(tag: any): string {
    let part1 = 'btn-';
    if (this.isPrimaryTag(tag)) {
      part1 = part1+'outline-dark';
    } else
    if (tag.score < 25) {
      part1 = part1 + 'danger';
    } else
    if (tag.score < 50) {
      part1 = part1+ 'warning';
    } else
    if (tag.score < 75) {
      part1 = part1+ 'info';
    } else {
      part1 = part1 + 'success';
    }
    return part1;
  }

  isPrimaryTag(tag: any): boolean {
    return (tag.name == 'Molecular' || tag.name == 'Social' || tag.name == 'Phenotypical');
  }

  reorderResults(): void {
    this.datasets = _.sortBy(this.datasets, (dataset: Dataset) => {
      return 0-dataset.score;
    })
  }
}
