import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {LoopBackAuth} from "../../../shared/sdk/services/core";


@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: LoopBackAuth) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path: string = next.url[0] ? next.url[0].path : '';
    if (((path === 'login') || (path === 'register')) && this.isAuthenticated()) {
      this.router.navigate(['/']);
      return false;
    } else if (((path === 'login') || (path === 'register')) && (!this.isAuthenticated()) ) {
      return true;
    } else {
      // @ts-ignore
      if (((path !== 'login') || (path !== 'register')) && (!this.isAuthenticated()) ) {
            this.router.navigate(['/login']);
            return true;
          } else {
            return true;
          }
    }
  }

  isAuthenticated(): boolean {
    if (this.auth.getCurrentUserData()) {
      /*console.log('checking Token: ', this.auth.getCurrentUserData().id);
      console.log('current access token: ', this.auth.getAccessTokenId());
      console.log('current user: ', this.auth.getCurrentUserData());
      console.log('current token: ', this.auth.getToken());
      console.log('current user id: ', this.auth.getCurrentUserId());*/
      return true;
    } else {
      alert('Requires user login. You will be redirected to the login page.')
      return false;
    }
    // return this.auth.getCurrentUserData().id != null;
    // return this.auth.getToken() != null;
  }
}
