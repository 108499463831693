import {AfterViewInit, AfterContentInit, Component, ElementRef, OnInit} from '@angular/core';

import {LoopBackAuth} from '../../shared/sdk/services/core';
import {DatasetApi, UserApi} from '../../shared/sdk/services/custom';

import { faSearch, faMap } from '@fortawesome/free-solid-svg-icons';
import {Dataset} from '../../shared/sdk/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  currentUserData: any;
  faSearch = faSearch;
  faMap = faMap;
  series;
  table2a;
  table2b;

  statistics: {
    counts: {
      total: number,
      phenotypic: number,
      social: number,
      molecular: number,
    },
    scores: {
      total: number,
      phenotypic: number,
      social: number,
      molecular: number,
    },
    datasets: [Dataset],
  };

  currentUserExist(): Boolean {
    if (this.authService.getCurrentUserData()) {
     this.currentUserData = this.authService.getCurrentUserData();
     return true;
    } else {
      this.currentUserData = null;
      return false;
    }
  }

  constructor(private authService: LoopBackAuth, private userApi: UserApi,
              private datasetApi: DatasetApi, private elementRef: ElementRef) {
  }

  logOut(): void {
    this.userApi.logout();
    localStorage.removeItem('rwdQuery');
    console.log('Logout successful');
  }

  pieChartLabel(series: any[], name: string): string {
    const item = series.filter(data => data.name === name);
    if (item.length > 0) {
      return item[0].label;
    }
    return name;
  }

  ngOnInit() {
    this.datasetApi.getStatistics().subscribe((data) => {
      this.statistics = data.statistics;
      this.series = [
        {
          name: 'Molecular',
          value: this.statistics.counts.molecular,
          label: 'Molecular'
        },
        {
          name: 'Social',
          value: this.statistics.counts.social,
          label: 'Social'
        },
        {
          name: 'Phenotypic',
          value: this.statistics.counts.phenotypic,
          label: 'Phenotypic'
        }
      ];
      this.table2b = [
        {
          name: 'Social',
          value: this.statistics.scores.social
        },
        {
          name: 'Molecular',
          value: this.statistics.scores.molecular
        },
        {
          name: 'Phenotypical',
          value: this.statistics.scores.phenotypic
        }
      ];

      this.table2a = [{
        name : 'Overall score',
        value: this.statistics.scores.total
      }
      ];
    });
  }

  /*ngAfterContentInit() {
    const scene = document.getElementById('scene');
    const parallaxInstance = new Parallax(scene, {
      relativeInput: true,
      hoverOnly: true
    });
  }*/

  ngAfterViewInit() {
    /*this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = 'brown';
  */}

}
